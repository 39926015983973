import React, { useContext, useEffect, useState } from "react";
import Image from "next/image";
import { isEmpty } from "lodash";
import cn from "classnames";

import { ShopifyProductDAO } from "@services/ShopifyProductDAO";
import { ShopContext } from "@context/shopContext";
import { ShopifyCollectionService } from "@services/ShopifyCollectionService";
import { addedToCartFBEvent } from "@utils/pixel";

const shopifyCollectionService = new ShopifyCollectionService();

const AddonProductCard = ({ productData }) => {
  const { cartCurrency, addToCart, addManyToToCart } = useContext(ShopContext);
  const [productDAO, setProductDAO] = useState(null);
  const isTravelPack = productData.productType === "Travel Packs";

  useEffect(() => {
    setProductDAO(new ShopifyProductDAO(productData, cartCurrency));
  }, []);
  if (isEmpty(productDAO)) {
    return null;
  }

  const onAdd = () => {
    addedToCartFBEvent(productDAO);
    if (productData.productType === "Bundles") {
      shopifyCollectionService
        .getCollectionByHandle(productData.handle)
        .then(collection => {
          const lineItems = collection.products.edges.map(c => {
            return {
              id: c.node.variants.edges[0].node.id,
              quantity: 1,
              sellingPlanId: null
            };
          });
          addManyToToCart(lineItems);
        });
    } else {
      addToCart(productDAO.firstVariant);
    }
  };

  return (
    <div className="h-fit h-auto w-full px-3 py-2">
      <div className="mb-1 flex items-center">
        <a
          href={`/products/${productData.handle}`}
          className={cn(
            "h-[75px] w-[75px] min-w-[75px] object-contain",
            isTravelPack && "pointer-events-none"
          )}>
          <div className="relative h-full w-full">
            <Image
              alt="productImageAltText"
              layout="fill"
              objectFit="contain"
              src={productDAO?.mainImage}
            />
          </div>
        </a>
        <a
          href={`/products/${productData.handle}`}
          className={` ${isTravelPack ? "pointer-events-none" : ""}`}>
          <h3 className="sofia-pro-s sofia-pro-b text-center text-[12px] leading-[1.2] text-gl-dark-blue">
            {productDAO?.title}
          </h3>
        </a>
      </div>
      <button
        className="sofia-pro-m w-full rounded-4 bg-[#2589bd] py-[8px] px-[10px] text-[12px] leading-[1] text-white transition-all hover:bg-dark"
        onClick={onAdd}>
        Add <span className="opacity-50">•</span>{" "}
        {
          productDAO.getProductVariantPrice(productDAO.firstVariant)
            .discountedPrice
        }
      </button>
    </div>
  );
};
export default AddonProductCard;
