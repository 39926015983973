import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";
import Link from "next/link";

import { ShopifyProductService } from "@services/ShopifyProductService";
import { ShopifyProductDAO } from "@services/ShopifyProductDAO";
import { ShopContext } from "@context/shopContext";
import ProductStars from "@components/Product/ProductStars/ProductStars";
import { ShopifyCollectionService } from "@services/ShopifyCollectionService";
import { addedToCartFBEvent } from "@utils/pixel";

const shopifyCollectionService = new ShopifyCollectionService();

export const Product = ({ product }) => {
  const shopifyProductService = new ShopifyProductService();

  const { cartCurrency, addToCart, addManyToToCart } = useContext(ShopContext);

  const [currentProduct, setCurrentProduct] = useState(null);
  const [productDAO, setProductDAO] = useState(null);

  const getProduct = async () => {
    const shopifyProductData = await shopifyProductService.getProductByHandle(
      product.slug
    );
    const newProductDAO = new ShopifyProductDAO(
      shopifyProductData,
      cartCurrency
    );
    setCurrentProduct(newProductDAO.firstVariant);
    setProductDAO(newProductDAO);
  };

  useEffect(() => {
    if (product) getProduct();
  }, [product]);

  return currentProduct ? (
    <div className="watch-good-product mb-4 flex min-w-[300px] cursor-pointer gap-4 overflow-hidden rounded-8 bg-[#15233C] pr-4 transition-all md:bg-white">
      <Link href={`products/${product.slug}`}>
        <a className="w-[108px] min-w-[108px] bg-[#F6F6F6] p-4 transition-all hover:opacity-50 md:rounded-8">
          <img
            className="h-full w-full object-contain"
            src={
              product?.content?.product_images_container[0]?.main_images[0]
                ?.image?.filename
            }
            alt=""
          />
        </a>
      </Link>
      <div className="flex flex-col justify-center pt-2 pb-3 md:mt-0 md:pb-0">
        <Link href={`products/${product.slug}`}>
          <a className="transition-all hover:opacity-50">
            <h4 className="fex-col sofia-pro-s flex items-center py-1 text-[18px] leading-tight text-white md:text-[#15233C]">
              {product?.name}
            </h4>
            <h2 className="sofia-pro-b text-left text-[16px] text-[#83B052]">
              ${currentProduct?.variantPrice}{" "}
              <span
                className={cn({
                  "sofia-pro-r text-[#15233C]/50 line-through":
                    currentProduct?.compareAtPrice
                })}>
                {currentProduct?.compareAtPrice &&
                  `$${currentProduct.compareAtPrice}`}
              </span>
            </h2>
          </a>
        </Link>
        {currentProduct?.sku && (
          <ProductStars productId={productDAO?.productId} />
        )}
        <button
          className={
            "sofia-pro-m mt-2 h-max w-full rounded-8 bg-gl-blue py-2 px-[20px] text-center text-[14px] text-white hover:bg-[#07496c]"
          }
          onClick={() => {
            addedToCartFBEvent(productDAO);
            if (productDAO.productType === "Bundles") {
              shopifyCollectionService
                .getCollectionByHandle(productDAO.handle)
                .then(c => {
                  const lineItems = c.products.edges.map(c => {
                    return {
                      id: c.node.variants.edges[0].node.id,
                      quantity: 1,
                      sellingPlanId: null
                    };
                  });
                  addManyToToCart(lineItems);
                });
            } else {
              addToCart({ id: currentProduct?.id, sellingPlanId: null });
            }
          }}>
          Add to cart
        </button>
      </div>
    </div>
  ) : null;
};
